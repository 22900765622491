import { defineStore } from 'pinia';
import { ISbStoryData } from '@storyblok/js';

export const useStoriesStore = defineStore('stories', {
  actions: {
    async fetchData() {
      // only fetch data if this store is empty
      if (this.stories.length) return;

      const storyblokApi = useStoryblokApi();

      const perPage = 100;
      let page = 1;
      let stories: never[] = [];
      let loop = true;
      let spaceVersion;

      // Get Space Version
      await storyblokApi.get('cdn/spaces/me', {}).then((response) => {
        spaceVersion = response.data.space.version;
      });

      while (loop) {
        await storyblokApi
          .get('cdn/stories', {
            cv: spaceVersion,
            page,
            per_page: perPage,
            resolve_links: 'url',
            sort_by: 'position:asc',
            // @ts-ignore
            version: process.env.STORYBLOK_API_VERSION || 'draft',
          })
          .then((response) => {
            stories = stories.concat(response.data.stories);
            if (response.data.stories.length === perPage) page++;
            else loop = false;
          })
          .catch((error) => {
            loop = false;
            // eslint-disable-next-line no-console
            console.error(error);
          });
      }

      this.stories = stories;
    },
  },
  getters: {
    getAllStories: (state: { stories: ISbStoryData[] }) => state.stories,
    getGDPR: (state: { stories: ISbStoryData[] }) => {
      return state.stories.find((item: ISbStoryData) => item.name === 'GDPR');
    },
    getGlobalFooter: (state: { stories: ISbStoryData[] }) => {
      return state.stories.find(
        (item: ISbStoryData) => item.name === 'Global Footer'
      );
    },
    getGlobalHeader: (state: { stories: ISbStoryData[] }) => {
      return state.stories.find(
        (item: ISbStoryData) => item.name === 'Global Header'
      );
    },
    getStoriesByType:
      (state: { stories: ISbStoryData[] }) => (types: string[]) => {
        return state.stories.reduce(
          (allItems: ISbStoryData[], item: ISbStoryData) => {
            if (
              item.content.component &&
              types.includes(item.content.component)
            ) {
              return [...allItems, item];
            } else {
              return allItems;
            }
          },
          []
        );
      },
    getStoriesInFolder:
      (state: { stories: ISbStoryData[] }) => (folder: string) => {
        return state.stories.reduce(
          (allItems: ISbStoryData[], item: ISbStoryData) => {
            const slug = item.full_slug;
            const createArray = (s: string) =>
              s.replace(/^\//, '').replace(/\/$/, '').split('/');
            const slugArray = createArray(slug);
            const folderArray = createArray(folder);

            if (
              slug.startsWith(`${folder}/`) &&
              slugArray.length > 0 &&
              folderArray.length > 0 &&
              folderArray.length <= slugArray.length &&
              folderArray.every((f, i) => slugArray[i] === f)
            ) {
              return [...allItems, item];
            } else {
              return allItems;
            }
          },
          []
        );
      },
    getStoryByFullSlug:
      (state: { stories: ISbStoryData[] }) => (fullSlug: string) => {
        if (state.stories.length)
          return state.stories.find(
            (item: ISbStoryData) =>
              item.full_slug.replace(/^\//, '').replace(/\/$/, '') ===
              fullSlug.replace(/^\//, '').replace(/\/$/, '')
          );
      },
    getStoryById: (state: { stories: ISbStoryData[] }) => (id: string) => {
      return state.stories.find((item) => item.uuid === id);
    },
  },
  state: () => ({
    stories: [],
  }),
});
